import React, { useEffect, useState } from "react";
import "./App.css";
import { HashRouter as Router, Routes, Route } from "react-router-dom";
import Header from "./components/header";
import { HomePage } from "./pages/home_page";
import strings_en from "./languages/strings_en.json";
import strings_it from "./languages/strings_it.json";
import { Localization } from "./component_library/localization";
import { AboutPage } from "./pages/about_page";
import { FeaturesPage } from "./pages/features_page";
import { PricingPage } from "./pages/pricing_page";
import { ContactPage } from "./pages/contact_page";
import { WhyMrtPage } from "./pages/why_mrt_page";
import { FreeTrialPage } from "./pages/free_trial_page";
import { FaqPageUsers } from "./pages/faq_page_users";
import { FaqPagePotentialCustomers } from "./pages/faq_page_potential_customers";
import { FooterSection } from "./components/footer_section";
import { ChatWidget } from "./components/chat_widget";
import { setupGoogleAnalytics, withGoogleAnalytics } from "./network/google_analytics";
import { Text } from "./component_library/text";

export const MY_ROYAL_TREE_APP_LOGIN_URL = "https://app.myroyaltree.com";
export const MY_ROYAL_TREE_APP_SIGNUP_URL = "https://app.myroyaltree.com/signup";


//Localization.addLanguage("en", strings_en);
//Localization.addLanguage("it", strings_it);

setupGoogleAnalytics("G-M83QZSHTPB");


function App() {

  const [stringsEn, setStringsEn] = useState(null);
  const [stringsIt, setStringsIt] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    Promise.all([
      fetch("/languages/strings_en.json").then(response => response.json()),
      fetch("/languages/strings_it.json").then(response => response.json())
    ]).then(([enData, itData]) => {
      setStringsEn(enData);
      setStringsIt(itData);
      Localization.addLanguage("en", enData);
      Localization.addLanguage("it", itData);
      setLoading(false);
    });
  }, []);

  if (loading) {
    return <Text>Loading...</Text>;
  }

  return (
    <Router>
      <Header />
      <ChatWidget />
      <Routes>
        <Route path="/" element={withGoogleAnalytics(HomePage)} />
        <Route path="/features" element={withGoogleAnalytics(FeaturesPage)} />
        <Route path="/why-mrt" element={withGoogleAnalytics(WhyMrtPage)} />
        <Route path="/pricing" element={withGoogleAnalytics(PricingPage)} />
        <Route path="/about" element={withGoogleAnalytics(AboutPage)} />
        <Route path="/contact" element={withGoogleAnalytics(ContactPage)} />
        <Route path="/faq-user" element={withGoogleAnalytics(FaqPageUsers)} />
        <Route path="/faq-potential-customer" element={withGoogleAnalytics(FaqPagePotentialCustomers)} />
        <Route path="/free-trial" element={withGoogleAnalytics(FreeTrialPage)} />
      </Routes>
      <FooterSection />
    </Router>
  );
}

export default App;
